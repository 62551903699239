<template>
	<div class="row">
		<div class="col">
		    <form @submit.prevent="checkForm" class="mb-4">
		        <div class="row">
		            <div class="col-12" v-if="form_message !== ''">
		                <ErrorAlert :messageI18n="form_message" />
		            </div>
		        </div>

				<div class="row" v-if="invoice_nums.length == 0">
					<div class="col">
						<div class="form-group">
							<label for="accountingplan_id">{{ $t('tiers.billing_entity') }} *</label>
							<e-select
								v-model="accounting_plan"
								id="accountingplan_id"
								track-by="accountingplan_label"
								label="accountingplan_label"
								:placeholder="getTrad('tiers.rechercher_billing_entity')"
								:selectedLabel="getTrad('global.selected_label')"
								:options="accounting_plans"
								:searchable="true"
								:allow-empty="false"
								:loading="isLoadingAccountingPlan"
								:show-labels="false"
								:class="{ 'is-invalid': errors && errors.indexOf('Accounting Plan') > -1 }"
							>
								<template slot="singleLabel" slot-scope="{ option }">{{ option.accountingplan_label }}</template>
								<template slot="noOptions">{{ $t('global.list_empty') }}</template>
							</e-select>
						</div>
					</div>
				</div>
		        
		        <div class="row">
				<div class="col-12 col-sm-6">
					<div class="form-group">
						<label for="payment_method">{{ $t('payment.payment_method') }} *</label>
						<e-select
							v-show="show_payment_methods"
							id="payment_method"
							:class="{ 'is-invalid': errors && errors.indexOf('Method') > -1 }"
							v-model="payment.payment_method"
							:options="payment_methods"
							:loading="loading_method"
							track-by="paymentmethod_id"
							label="translated_option"
							:allow-empty="false"
						/>
					</div>
				</div>

					<div class="col-12 col-sm-6">
						<div class="form-group">
							<label for="payment_date">{{ $t('payment.payment_date') }} *</label>
							<e-datepicker v-model="payment.payment_date"></e-datepicker>
						</div>
					</div>
				</div>

				<div class="row" v-if="display_bankcheck">
					<div class="col-12 col-sm-6">
						<div class="form-group">
							<label for="payment_bank">{{ $t('payment.payment_bank') }}</label>
							<e-select
								id="payment_bank"
								v-model="payment.payment_bank"
								:options="payment_banks"
								track-by="paymentbank_label"
								label="paymentbank_label"
								:allow-empty="false"
							/>
						</div>
					</div>

					<div class="col-12 col-sm-6">
						<div class="form-group">
							<label for="payment_bankcheck">{{ $t('payment.payment_bankcheck') }}</label>
							<input id="payment_bankcheck" class="form-control" v-model="payment.payment_bankcheck" :placeholder="this.getTrad('payment.payment_bankcheck_placeholder')">
						</div>
					</div>
				</div>

				<div v-if="diplay_message_cc" class="row">
					<div class="col">
						<b-alert show variant="warning">
							{{ getTrad('payment.montant_sup_cc', {x:payment.payment_method.balance}) }}<br>
							<a href="#" @click.prevent="appliqueSoldeCC">{{ $t('payment.appliquer_solde_cc') }}</a>
						</b-alert>
					</div>
				</div>

				<div class="row">
					<div class="col">
						<div class="form-group">
							<label for="payment_amount">{{ $t('payment.payment_amount') }} *</label>
							<input id="payment_amount" class="form-control" v-model="payment.payment_amount" :placeholder="this.getTrad('payment.payment_amount_placeholder')" :class="{ 'is-invalid': errors && errors.indexOf('Amount') > -1 }">
						</div>
					</div>
					<div class="col-12">
						<b-alert v-if="error_amount_avoir" variant="danger" show>
                            {{ $t('payment.positive_amount_avoir') }}
                        </b-alert>
						<b-alert v-if="error_avoir_negativ" variant="danger" show>
                            {{ $t('payment.to_negative_amount') }}
                        </b-alert>
					</div>
				</div>

				<div class="row">
					<div class="col">
						<div class="form-group">
							<label for="payment_comment">{{ $t('payment.payment_comment') }}</label>
							<textarea id="payment_comment" class="form-control" v-model="payment.payment_comment" :placeholder="this.getTrad('payment.payment_comment_placeholder')" rows="2"></textarea>
						</div>
					</div>
				</div>

				<div class="row" v-if="invoice_nums.length > 0">
					<div class="col">
						<p>
							<span v-if="invoice_nums.length == 1">{{ $t('payment.add_payment_for_invoice') }} :</span>
							<span v-else>{{ $t('payment.add_payment_for_invoice_multiple') }} :</span>
							<ul>
								<li v-for="invoice_num in invoice_nums" :key="invoice_num">{{ invoice_num }}</li>
							</ul>
						</p>
					</div>
				</div>
	        	<div v-if="!error_amount_avoir || !error_avoir_negativ" class="row d-xl-block d-md-none">
	        		<div class="col-8 m-auto text-center">
	            		<b-button pill block variant="primary" @click.prevent="submit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
	        		</div>
		        </div>
		    </form>
		</div>
        <div v-if="!error_amount_avoir || !error_avoir_negativ" class="col-xl-12 d-xl-none mb-2">
        	<div class="row">
        		<div class="col-8 m-auto text-center">
            		<b-button pill block variant="primary" @click.prevent="submit"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("action.ajouter") }}</b-button>
        		</div>
        	</div>
        </div>
		<div v-if="invoice_ids.length > 0" class="col-12 col-xl-7">
			<h3>{{ $t('payment.other_invoices_for_horses') }}</h3>
			<CustomTable
				id_table="impayes_reglement"
				ref="impayes_reglement"
				:items="unpaid"
				:busy.sync="table_busy"
				primaryKey="uid"
				:hide_if_empty="true"
            />	
		</div>
	</div>
</template>

<script type="text/javascript">
	import TableAction from "@/mixins/TableAction.js"
	import Navigation from "@/mixins/Navigation.js"
	import Config from "@/mixins/Config.js"
	import Payment from "@/mixins/Payment.js"
	import Accounting from "@/mixins/Accounting.js"
	import _cloneDeep from 'lodash/cloneDeep'


	export default {
		name: "AddPayment",
		mixins: [Config, TableAction, Navigation, Payment, Accounting],
		props: {
			ready: {
				type: Boolean,
				default: false
			},
			invoice_ids: {
				type: Array,
				default: () => {
					[]
				}
			},
			invoice_nums: {
				type: Array,
				default: () => {
					[]
				}
			},
			invoice_accountingplans: {
				type: Array,
				default: () => {
					[]
				}
			},
			invoice_balances: {
				type: [ Number ],
				default: () => ( 0 )
			},
			tiers_id: {
				type: [ String, Number ],
				default: () => ( 0 )
			},
			invoice_tiers: {
				type: [ String, Number ],
				default: () => ( 0 )
			},
			processing: {
				type: [ Boolean ],
				default: false
			},
			submit_payment: {
				type: [ Boolean ],
				default: false
			}
		},
		data () {
			return {
				form_message: '',
				payment_methods: this.getConfig('payment_method'),
				payment_methods_save: this.getConfig('payment_method'),
				payment_banks: this.getConfig('payment_bank'),
				loading_method: false,
				display_bankcheck: false,
				diplay_message_cc: false,
				payment: {
					payment_method: null,
					payment_date: new Date(),
					payment_bank: null,
					payment_amount: null,
				},
				accounting_plan: null,
				isLoadingAccountingPlan: false,
				errors: [],
				accounting_plans: [],
				current_account: null,
				unpaid: [],
				table_busy: true,
				error_amount_avoir: false,
				error_avoir_negativ: false,
				show_payment_methods: true
			}
		},
		mounted() {
			this.init_component()
		},
		created () {
		},
		methods: {
			async init_component() {
				if(this.invoice_balances) {
					this.payment.payment_amount = this.invoice_balances.toString().toFloat().toFixed(2)
				}
				
				//Si plusieurs compta je peux pas payer en compte courant
				if(this.invoice_accountingplans && this.invoice_accountingplans.length > 1) {
					this.removeCurrentAccountMethod()
				}

				if(this.invoice_accountingplans && this.invoice_accountingplans.length == 1) {
					await this.currentAccount(true)
				}
				else if(this.invoice_accountingplans && this.invoice_accountingplans.length > 1) {
					await this.setTranslateOption()
				}

				if(this.invoice_ids.length == 0) {
					this.isLoadingAccountingPlan = true
					this.currentAccount(false)
					this.accounting_plans = await this.loadAccountingPlans()
					this.isLoadingAccountingPlan = false
				}
				else {
					this.unpaid = await this.loadHorseUnpaidByInvoices(this.invoice_ids.join(','))
					this.table_busy = false
				}

				// Formatage payment banks
				this.payment_banks = this.payment_banks.map((bank) => {
					bank.paymentbank_label = bank.paymentbank_label + ' - ' + bank.paymentbank_code
					return bank
				})

				this.$emit('update:ready', true)
			},
			async checkForm() {

			},

			async addPayment(){

				this.errors = []
				this.$emit('update:processing', true)

				if(this.payment.payment_amount == null)
				{
					this.errors.push("Amount")
				}

				if(!Date.parse(this.payment.payment_date))
				{
					this.errors.push("Date")
				}

				if(this.payment.payment_method == null)
				{
					this.errors.push("Method")
				}

				if(this.invoice_ids.length == 0 && this.accounting_plan == null)
				{
					this.errors.push("Accounting Plan")
				}

				if(this.errors.length > 0)
				{
					this.$emit('update:processing', false)
					return false
				}


				let result = null
				if(this.accounting_plan != null)
				{
					this.payment.accounting_plan = this.accounting_plan.accountingplan_id
				}
				this.payment.invoice_ids = this.invoice_ids
				this.payment.tiers_id = this.$sync.replaceWithReplicated('tiers', this.tiers_id)

				const temp_send = _cloneDeep(this.payment)
				temp_send.payment_method = this.payment.payment_method.paymentmethod_id
				temp_send.payment_bank   = this.payment.payment_bank ? this.payment.payment_bank.paymentbank_id : null
				result = await this.createPayment(temp_send)
				
				if(result) {
					this.$emit('update:processing', false)
					this.successToast()
					return true
				}
				else {
					this.$emit('update:processing', false)
				}
				
			},

			async currentAccount(keys_id){
				this.loading_method = true
				this.current_account = await this.loadCurrentAccount(this.tiers_id, keys_id)
				await this.displayCurrentAccount()

				// Formatage payment methods
				this.payment_methods.map((option) => {
					let translated_option = this.getTrad('payment.payment_method_labels.' + option.paymentmethod_code.toLowerCase())
					if (option.paymentmethod_code == 'CC') {
						const regExp = /\(([^)]+)\)/;
						const matches = regExp.exec(option.paymentmethod_label);
						if(matches && matches[0]){
							translated_option += ' ' + matches[0]
						}
					}
					option.translated_option = translated_option
					return option
				})
				this.show_payment_methods = false
				this.$nextTick(() => {
					this.show_payment_methods = true
				})

				this.loading_method = false
			},

			setTranslateOption() {
				this.loading_method = true
				this.payment_methods = this.payment_methods.map(option => ({...option, translated_option: this.getTrad('payment.payment_method_labels.' + option.paymentmethod_code.toLowerCase())}))
				this.show_payment_methods = false
				this.$nextTick(() => {
					this.show_payment_methods = true
				})
				this.loading_method = false
			},

			setAmount(val){
				this.payment.payment_amount = val
			},

			async displayCurrentAccount(){
				const index_current_account = this.payment_methods.findIndex(elem => elem.paymentmethod_code == "CC")
				this.payment_methods[index_current_account].paymentmethod_label = this.payment_methods_save[index_current_account].paymentmethod_label + " (0)"
				this.payment_methods[index_current_account].balance = 0

				if(this.current_account && Object.keys(this.current_account).length > 0) {
					if(this.accounting_plan) {
						if(this.current_account[this.accounting_plan.accountingplan_label]) {
							const balance = this.current_account[this.accounting_plan.accountingplan_label].balance ?? 0
							const currency = this.current_account[this.accounting_plan.accountingplan_label].currency ?? 'EUR'
							this.payment_methods[index_current_account].paymentmethod_label = this.payment_methods_save[index_current_account].paymentmethod_label + " (" + (balance / 100) + " " + currency + ")"
							this.payment_methods[index_current_account].balance = balance / 100
						}
					}
					else {
						if(!this.invoice_accountingplans || this.invoice_accountingplans.length == 0) {
							const account_id = Object.keys(this.current_account)[0]
							console.log(account_id);
							const balance = this.current_account[account_id].balance ?? 0
							const currency = this.current_account[account_id].currency ?? 'EUR'
							this.payment_methods[index_current_account].paymentmethod_label = this.payment_methods_save[index_current_account].paymentmethod_label + " (" + (balance / 100) + " " + currency + ")"
							this.payment_methods[index_current_account].balance = balance / 100
						}
						else if(this.current_account[this.invoice_accountingplans[0]]) {
							const balance = this.current_account[this.invoice_accountingplans[0]].balance ?? 0
							const currency = this.current_account[this.invoice_accountingplans[0]].currency ?? 'EUR'
							this.payment_methods[index_current_account].paymentmethod_label = this.payment_methods_save[index_current_account].paymentmethod_label + " (" + (balance / 100) + " " + currency + ")"
							this.payment_methods[index_current_account].balance = balance / 100
						}
					}
				}
			},

			async removeCurrentAccountMethod(){
				const index_current_account = this.payment_methods.findIndex(elem => elem.paymentmethod_code == "CC")
				this.payment_methods.splice(index_current_account, 1);
			},

			submit() {
				this.$emit('update:submit_payment', !this.submit_payment)
			},

			checkCC() {
				if(this.payment.payment_method.balance < parseFloat(this.payment.payment_amount))
				{
					this.diplay_message_cc = true
				}
			},

			appliqueSoldeCC() {
				this.payment.payment_amount = this.payment.payment_method.balance
			}

		},
		watch:{
			'payment.payment_method'(val) {
				this.display_bankcheck = false
				this.diplay_message_cc = false
				
				if(val.paymentmethod_code == "CH") {
					this.display_bankcheck = true
				}
				else if(val.paymentmethod_code == "CC") {
					this.checkCC()
				}
			},
			'accounting_plan'(val){
				if(this.invoice_nums.length == 0 && val != null)
				{
					this.displayCurrentAccount()
				}
			},
			'payment.payment_amount'(val) {
				this.error_amount_avoir = val > 0 && this.invoice_balances < 0
				this.error_avoir_negativ = val < 0 && this.invoice_balances < 0 && val < this.invoice_balances
			}
		},
		components: {
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
		}
	}
</script>